import React from 'react'
import "./Footer.css"
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div style={{background: "black"}}>
      <div className="container">
  <footer className="pt-5 pb-2">
    <ul className="nav justify-content-center border-bottom pb-3 mb-3">
      <li className="nav-item"><Link to="/" className="nav-link px-2 text-body-secondary">Home</Link></li>
      <li className="nav-item"><Link to="/" className="nav-link px-2 text-body-secondary">Features</Link></li>
      <li className="nav-item"><Link to="/" className="nav-link px-2 text-body-secondary">Pricing</Link></li>
      <li className="nav-item"><Link to="/" className="nav-link px-2 text-body-secondary">FAQs</Link></li>
      <li className="nav-item"><Link to="/" className="nav-link px-2 text-body-secondary">About</Link></li>
    </ul>
    <p className="text-center text-body-secondary mb-0">© 2024 Elysées Wealth Management, Inc</p>
  </footer>
</div>
    </div>
  )
}

export default Footer
