import logo from './logo.svg';
import './App.css';
import {Route, Routes, useLocation} from "react-router-dom"
import Navbar from './Pages/Navbar';
import Home from './Components/Home.js';
import Footer from './Components/Footer/Footer.js';
import SignUp from './Components/SignUp/SignUp.js';
import SignIn from './Components/SignIn/SignIn.js';
import HowItWorks from './Pages/HowItWorks.js';
import Dashboard from './Pages/Dashboard.js';

function App() {
  const location = useLocation()
  return (
    <div className="">
      
      {location.pathname !== '/dashboard' && <Navbar />}
      <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/signup' element={<SignUp/>} />
      <Route path='/login' element={<SignIn/>} />
      <Route path='/dashboard' element={<Dashboard/>} />
      <Route path='/howitworks' element={<HowItWorks/>} />
      </Routes>
      <Footer/>
    
    </div>
  );
}

export default App;
