import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import DashboardDisplay from "../Components/Dashboard/DashboardDisplay";
import BackTest from "../Components/Dashboard/BackTest";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import LiveTrading from "../Components/Dashboard/LiveTrading";
import Sidebar from "../Components/Dashboard/Sidebar";

function Dashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const [userResponse, setUserResponse] = useState("");
  const [display, setDisplay] = useState(1);

  // Load backend URL from environment variables
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  console.log("Backend Url: ", backendUrl)
  useEffect(() => {
    const fetchDashboardData = async () => {
      const accessToken = localStorage.getItem("accessToken");
  
      if (!accessToken) {
        toast.error("Access token not found. Please log in.");
        return;
      }
  
      // Log the access token to verify it's being retrieved correctly
      console.log("Access Token from localStorage:", accessToken);
  
      try {
        // Pass the token as part of the URL
        console.log("Sending request to verify_t with token:", accessToken);

        const userResponse = await axios.get(`${backendUrl}/verify_t/${accessToken}`);
        
        console.log("User Response from verify_t:", userResponse.data);
        setUserResponse(userResponse.data);
  
        // Fetch dashboard data
        const dashboardResponse = await axios.post(`${backendUrl}/dashboard`, {
          email: userResponse.data.email,
          username: userResponse.data.username,
        });
        
        console.log("Dashboard Response:", dashboardResponse);
        setDashboardData(dashboardResponse.data);
  
        toast.success("Dashboard data loaded successfully!");
      } catch (error) {
        if (error.response) {
          console.error("Error Status:", error.response.status);
          console.error("Error Data:", error.response.data);
          toast.error("Failed to fetch data. Please try again.");
        } else if (error.request) {
          console.error("No response received:", error.request);
          toast.error("No response from server. Please try again later.");
        } else {
          console.error("Error:", error.message);
          toast.error("An error occurred. Please try again.");
        }
      }
    };
  
    fetchDashboardData();
  }, [backendUrl]);

  return (
    <>
      <Toaster /> {/* Toast notifications */}
      <Sidebar userResponse={userResponse} />
      <div className="container justify-content-center my-sm-0 my-4 py-5">
        <div className="text-center mx-auto">
          <h1
            className="fw-bold resHeading"
            style={{ fontSize: "var(--font-size-39xl)" }}
          >
            Dashboard
          </h1>
        </div>
        <div className="container my-4">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-3 rounded-3 scaleUp text-center my-2 my-xl-0 px-0">
              <button
                className={`btn shadow-none text-center d-block w-100 ${
                  display === 1 ? "text-danger border-danger" : "text-white border"
                }`}
                onClick={() => {
                  setDisplay(1);
                }}
              >
                <div className="">Dashboard</div>
                <div className="small">
                  <em>Manage params & view trade</em>
                </div>
              </button>
            </div>

            <div className="col-md-3 rounded-3 scaleUp text-center my-2 my-xl-0 px-0">
              <button
                className={`btn shadow-none text-center d-block w-100 ${
                  display === 2 ? "text-danger border-danger" : "text-white border"
                }`}
                onClick={() => {
                  setDisplay(2);
                }}
              >
                <div className="">Backtest & Optimize</div>
                <div className="small">
                  <em>Test Strategies Safely</em>
                </div>
              </button>
            </div>
            <div className="col-md-3 rounded-3 scaleUp text-center my-2 my-xl-0 px-0">
              <button
                className={`btn shadow-none text-center d-block w-100 ${
                  display === 3 ? "text-danger border-danger" : "text-white border"
                }`}
                onClick={() => {
                  setDisplay(3);
                }}
              >
                <div className="">Live Mode</div>
                <div className="small">
                  <em>Real trading with funds</em>
                </div>
              </button>
            </div>
          </div>
        </div>
        <hr className="my-2" />
        {display === 1 && <DashboardDisplay dashboardData={dashboardData} />}
        {display === 2 && <BackTest />}
        {display === 3 && <LiveTrading userResponse={userResponse} />}
      </div>
    </>
  );
}

export default Dashboard;