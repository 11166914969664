import React from "react";
import { useState, useEffect } from "react";
import "./DashboardDisplay.css";
import ATOM from "../../frontend_display/trades/ATOM/ATOM_trades.json";
import BTC from "../../frontend_display/trades/BTC/BTC_trades.json";
import DASH from "../../frontend_display/trades/DASH/DASH_trades.json";
import DOGE from "../../frontend_display/trades/DOGE/DOGE_trades.json";
import ETH from "../../frontend_display/trades/ETH/ETH_trades.json";
import FLOW from "../../frontend_display/trades/FLOW/FLOW_trades.json";
import GALA from "../../frontend_display/trades/GALA/GALA_trades.json";
import LRC from "../../frontend_display/trades/LRC/LRC_trades.json";
import OP from "../../frontend_display/trades/OP/OP_trades.json";
import SAND from "../../frontend_display/trades/SAND/SAND_trades.json";
import SNX from "../../frontend_display/trades/SNX/SNX_trades.json";
import SOL from "../../frontend_display/trades/SOL/SOL_trades.json";
import XLM from "../../frontend_display/trades/XLM/XLM_trades.json";
import XTZ from "../../frontend_display/trades/XTZ/XTZ_trades.json";

function DashboardDisplay(props) {
  const [searchPosition, setSearchPosition] = useState("all");
  const [searchCoin, setSearchCoin] = useState("all");
  const [filteredTradeSummaries, setFilteredTradeSummaries] = useState([]);
  const [searchEntryDate, setSearchEntryDate] = useState("");
  const [searchExitDate, setSearchExitDate] = useState("");

  // Function to clean each item, replacing null or NaN values with "None"
  const cleanData = (dataArray) =>
    dataArray.map((item) =>
      Object.fromEntries(
        Object.entries(item).map(([key, value]) => [
          key,
          value === null || Number.isNaN(value) ? "None" : value,
        ])
      )
    );

  const mergedJSONData = [
    ...cleanData(ATOM),
    ...cleanData(BTC),
    ...cleanData(DASH),
    ...cleanData(DOGE),
    ...cleanData(ETH),
    ...cleanData(FLOW),
    ...cleanData(GALA),
    ...cleanData(LRC),
    ...cleanData(OP),
    ...cleanData(SAND),
    ...cleanData(SNX),
    ...cleanData(SOL),
    ...cleanData(XLM),
    ...cleanData(XTZ),
  ];

  // Use effect for filtering based on searchType and searchVariable
  useEffect(() => {
    if (!props.dashboardData) return; // Ensure dashboardData is defined before proceeding
    let result = props.dashboardData.portfolio.trade_summaries;
    console.log("result is", result);
    // Apply filtering based on searchCoin and searchPosition
    if (searchCoin !== "all" && searchPosition !== "all") {
      result = result.filter(
        (data) =>
          data.symbol === searchCoin && data.position_type === searchPosition
      );
    } else if (searchCoin !== "all" && searchPosition === "all") {
      result = result.filter((data) => data.symbol === searchCoin);
    } else if (searchPosition !== "all" && searchCoin === "all") {
      result = result.filter((data) => data.position_type === searchPosition);
    }

    // Apply filtering based on entryDate and exitDate if they are provided
    if (searchEntryDate) {
      result = result.filter((data) => data.entry_time >= searchEntryDate);
    }

    if (searchExitDate) {
      result = result.filter((data) => data.exit_time <= searchExitDate);
    }

    console.log("result after filter is", result);
    setFilteredTradeSummaries(result);
  }, [
    props.dashboardData,
    searchCoin,
    searchPosition,
    searchEntryDate,
    searchExitDate,
  ]);

  useEffect(() => {
    console.log("merged JSON is:", mergedJSONData);
    console.log("props.dashboard is:", props.dashboard);
    console.log(
      "searchCoin and searchPosition is:",
      searchCoin,
      searchPosition
    );
    console.log("filteredTradeSummaries is:", filteredTradeSummaries);
    console.log(
      "searchEntryDate and searchExitDate is:",
      searchEntryDate,
      searchExitDate
    );
  }, [
    searchCoin,
    searchPosition,
    mergedJSONData,
    filteredTradeSummaries,
    searchEntryDate,
    searchExitDate,
  ]);

  return (
    <div className="my-3 container">
      <div className="container row justify-content-evenly">
        <div
          className="col-md-3 border rounded-3 px-0 my-3"
          style={{
            width: "160px",
            height: "160px",
            maxHeight: "160px",
            maxWidth: "160px",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          }}
        >
          <div className="d-flex align-items-center text-center h-50">
            <span>Total PnL Value (USDT)</span>
          </div>
          <hr style={{ width: "100%", margin: 0 }} />
          <div className="d-flex align-items-center justify-content-center h-50">
            <span>
            {!props.dashboardData ? (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="spinner-border-sm spinner-border me-2"
                ></div>
              </div>
            ) :( <span className="fw-bold fs-3 text-primary">{props.dashboardData?.summary_metrics.total_pnl_value.toFixed(3)}</span>)}
            </span>
          </div>
        </div>

        <div
          className="col-md-3 border rounded-3 px-0 my-3"
          style={{
            width: "160px",
            height: "160px",
            maxHeight: "160px",
            maxWidth: "160px",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          }}
        >
          <div className="d-flex align-items-center h-50 text-center">
            Average PnL Percentage (%)
          </div>
          <hr style={{ width: "100%", margin: 0 }} />
          <div className="d-flex align-items-center justify-content-center h-50">
            <span>
              {!props.dashboardData ? (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="spinner-border-sm spinner-border me-2"
                ></div>
              </div>
            ) : (<span className="fw-bold fs-3 text-primary">{props.dashboardData?.summary_metrics.average_pnl_percentage.toFixed(
                3
              )} </span>)}
            </span>
          </div>
        </div>
        <div
          className="col-md-3 border rounded-3 px-0 my-3"
          style={{
            width: "160px",
            height: "160px",
            maxHeight: "160px",
            maxWidth: "160px",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          }}
        >
          <div className="d-flex align-items-center h-50 justify-content-center">
            Total Trades
          </div>

          <hr style={{ width: "100%", margin: 0 }} />
          <div className="d-flex align-items-center justify-content-center h-50">
            <span>
            {!props.dashboardData ? (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="spinner-border-sm spinner-border me-2"
                ></div>
              </div>
            ) :(<span className="fw-bold fs-3 text-primary">{ props.dashboardData?.summary_metrics.total_trades}</span>)}
            </span>
          </div>
        </div>

        <div
          className="col-md-3 border rounded-3 px-0 my-3"
          style={{
            width: "160px",
            height: "160px",
            maxHeight: "160px",
            maxWidth: "160px",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          }}
        >
          <div className="d-flex align-items-center h-50 justify-content-center">
            Win Rate(%)
          </div>
          <hr style={{ width: "100%", margin: 0 }} />
          <div className="d-flex align-items-center justify-content-center h-50">
            <span>
            {!props.dashboardData ? (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="spinner-border-sm spinner-border me-2"
                ></div>
              </div>
            ) : (<span className="fw-bold fs-3 text-primary">{ props.dashboardData?.summary_metrics.win_rate.toFixed(3)} </span>)}
            </span>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="fw-bold">
          Largest Gain (USDT):
          <span className="ms-2">
          {!props.dashboardData ? (
              <div className="d-flex align-items-start justify-content-start">
                <div
                  className="spinner-border-sm spinner-border me-2"
                ></div>
              </div>
            ) : props.dashboardData?.summary_metrics.largest_gain.toFixed(3)}
          </span>
        </div>
        <div className="fw-bold">
          Largest Loss (USDT):
          <span className="ms-2">
          {!props.dashboardData ? (
              <div className="d-flex align-items-start justify-content-start">
                <div
                  className="spinner-border-sm spinner-border me-2"
                ></div>
              </div>
            ) : props.dashboardData?.summary_metrics.largest_loss.toFixed(3)}
          </span>
        </div>
      </div>
      <div className="my-5 container">
        <div className="text-center">
          <h3>Combined Trade Summary</h3>
        </div>

        <div
          className="table-responsive mx-auto border rounded-3"
          style={{ maxWidth: "700px", maxHeight: "400px" }}
        >
          <table className="table align-middle text-white">
            <thead>
              <tr className="text-center align-middle">
                <th scope="col">#</th>
                <th scope="col">Entry Time</th>
                <th scope="col">Exit Time</th>
                <th scope="col">Symbol</th>
                <th scope="col">Timeframe</th>
                <th scope="col">Entry Price</th>
                <th scope="col">Exit Price</th>
                <th scope="col">Order Quantity</th>
                <th scope="col">TP</th>
                <th scope="col">Limit</th>
                <th scope="col">Status</th>
                <th scope="col">filltype status</th>
                <th scope="col">Position Type</th>
                <th scope="col">PnL Value (USDT)</th>
                <th scope="col">PnL Percentage</th>
                <th scope="col">Order ID</th>
              </tr>
            </thead>
            {!props.dashboardData && (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="spinner-border-sm spinner-border me-2"
                  aria-hidden="true"
                ></div>
                <strong role="status"> Loading...</strong>
              </div>
            )}
            <tbody>
              {props.dashboardData &&
                mergedJSONData.map((item, index) => {
                  return (
                    <tr key={index} className="text-center">
                      <th scope="row">{index + 1}</th>
                      <td>{item.entry_time || "-"}</td>
                      <td>{item.exit_time || "-"}</td>
                      <td>{item.symbol || "-"}</td>
                      <td>{item.timeframe || "-"}</td>
                      <td>{item.buyprice || "-"}</td>
                      <td>{item.sellprice || "-"}</td>
                      <td>{item.order_qty || "-"}</td>
                      <td>{item.tp === Number ? item.tp.toFixed(4) : "-"}</td>
                      <td>{item.limit || "-"}</td>
                      <td>{item.status || "-"}</td>
                      <td>{item.fill_type_status || "-"}</td>
                      <td>{item.position_type || "-"}</td>
                      <td>None</td>
                      <td>None</td>
                      <td>{item.order_id || "-"}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row mb-4 container">
        <div>
          <h3>Filter and Sort Trade Details</h3>
        </div>
        <div className="small mt-2">Select Coin Symbol:</div>
        <div className="w-25 my-2">
          <select
            className="form-select text-white grayBg"
            onChange={(e) => setSearchCoin(e.target.value)}
          >
            <option value="all" className="grayBg text-dark">
              All
            </option>
            <option value="SNX/USD:USD" className="grayBg text-dark">
              SNX/USD
            </option>
            <option value="DOGE/USD:USD" className="grayBg text-dark">
              DOGE/USD
            </option>
          </select>
        </div>

        <div className="my-2 small">
          Select Position
          <div className="form-check form-check-inline ms-2">
            <input
              className="form-check-input"
              type="radio"
              name="position"
              defaultChecked
              value="all"
              onChange={(e) => setSearchPosition(e.target.value)}
            />
            <label className="form-check-label">All</label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="position"
              value="long"
              onChange={(e) => setSearchPosition(e.target.value)}
            />
            <label className="form-check-label">Long</label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="position"
              id="short"
              value="short"
              onChange={(e) => setSearchPosition(e.target.value)}
            />
            <label className="form-check-label">Short</label>
          </div>
        </div>
        <div className="my-2 small">
          <span>Entry Date</span>
          <input
            className="mx-2 border rounded-3 grayBg text-white"
            type="date"
            onChange={(e) => setSearchEntryDate(e.target.value)}
          />
          <span>Exit Date</span>
          <input
            className="mx-2 border rounded-3 grayBg text-white"
            type="date"
            onChange={(e) => setSearchExitDate(e.target.value)}
          />
        </div>
      </div>
      {!props.dashboardData && (
        <div className="d-flex align-items-center">
          <div
            className="spinner-border-sm spinner-border me-2"
            aria-hidden="true"
          ></div>
          <strong role="status"> Loading...</strong>
        </div>
      )}
      {props.dashboardData &&
        filteredTradeSummaries.map((item, index) => {
          return (
            <div key={index} className="my-4 container">
              <h4>Symbol: {item.symbol}</h4>
              <div className="row justify-content-between">
                <div className="col-md-4">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      Position Type: {item.position_type}
                    </li>
                    <li className="list-group-item">
                      Entry Time: {item.entry_time}
                    </li>
                    <li className="list-group-item">
                      Exit Time: {item.exit_time}
                    </li>
                    <li className="list-group-item">
                      <button className="btn border text-white">View Trade Details</button>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2">
                  <div>PnL Value (USDT)</div>
                  <div className="fs-3">${item.pnl_value.toFixed(3)}</div>
                  <div className={`small ${item.pnl_percentage<0 ? "text-danger" : "text-success"}`} ><i className={`bi bi-arrow-${item.pnl_percentage<0 ? "down" : "up"}`}></i>{item.pnl_percentage.toFixed(2)}%</div>
                </div>
                <div className="col-md-2">
                  <div>Profit/Loss</div>
                  <div
                    className={`mt-3 d-flex justify-content-center align-items-center border rounded-3 ${item.pnl_value<0 ? "bg-danger" : "bg-success"}`}
                    style={{ height: "50px" }}
                  >
                    <div className="">{item.pnl_value<0 ? "Loss" : "Profit"}</div>
                  </div>
                </div>
              </div>
              <hr className="mt-4" />
            </div>
          );
        })}
    </div>
  );
}

export default DashboardDisplay;
