import styles from "./Navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import logo from "../Assets/logo.png"
import crossIcon from "../Assets/cart_cross_icon.png"


const Navbar = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    console.log("logout is clicked");
    navigate("/login");
  };

//   const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 750);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsSmallScreen(window.innerWidth < 750);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

  return (
    <>
    <nav
      className="fw-bold navbar navbar-expand-md"
      aria-label="Fourth navbar example"
      style={{
        width: "100%",
        backgroundColor: "black"
      }}
    >
      <div className="container-fluid px-md-5">
        <Link className="navbar-brand" to="/">
          <div className="d-flex">
            <div><img
              className="logo"
              loading="lazy"
              alt=""
              src={logo}
              style={{ width: "66px", height: "66px" }}
            /></div>
            <div className="d-inline"><p className="mb-0">Elysées Wealth</p> 
            <p className="mb-0">Management</p></div>
          </div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsExample04"
          aria-controls="navbarsExample04"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
</svg></span>
        </button>

        <div
          className="ps-3 ps-md-0 collapse navbar-collapse"
          id="navbarsExample04"
        >
          <ul className="navbar-nav mx-auto mb-2 mb-md-0">
            <li className="nav-item">
              <Link className={`nav-link active scaleUp`} aria-current="page" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link active scaleUp mx-md-3`}
                aria-current="page"
                to="/howitworks"
              >
                How It Works
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link active scaleUp me-md-3`}
                aria-current="page"
                to="/blog"
              >
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link active scaleUp`}
                aria-current="page"
                to="/aboutus"
              >
                About Us
              </Link>
            </li>
          </ul>
          <div className="">
            {!localStorage.getItem("accessToken") ? (
              <>
                <Link
                  type="button"
                  className={`btn px-3 border rounded-pill ${
                    location.pathname === "/login" ? "d-none" : null
                  } scaleUp `}
                  to="/login"
                  
                >
                  Sign In
                </Link>
                <Link
                  type="button"
                  className={`btn border rounded-pill px-3 ms-2 scaleUp`}
                  to="/signup"
                  
                >
                  Start Free Trial
                </Link>
                
              </>
            ) : (
              <>
                <Link
                  type="button"
                  className={`btn px-3 border rounded-pill scaleUp`}
                onClick={()=>{handleLogout()}}
                  
                >
                  Log out
                </Link>
                <Link
                  type="button"
                  className={`btn ms-2 px-3 border rounded-pill scaleUp`}
                  role="button"
                  data-bs-toggle="modal"
                  data-bs-target="#dashboardModal"
                >
                  Settings
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
    <hr />
    </>
  );
};

export default Navbar;
