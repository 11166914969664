import React from 'react'

function BootstrapSpinner() {
  return (
    <div className="d-flex align-items-center justify-content-center">
                <div
                  className="spinner-border-sm spinner-border me-2"
                ></div>
              </div>
  )
}

export default BootstrapSpinner
