import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "../Spinner";
import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';
import "./Optimization.css"

function Optimization(props) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  
  const optimization = async (data) => {
    console.log("Session ID:", props.sessionId);
    console.log("Backtest Data:", props.backTestData);
    console.log("Number of trials:", data.numTrials);

    const backendUrl = process.env.REACT_APP_BACKEND_URL; // Get backend URL from environment variable

    try {
      setLoading(true);
      const response = await axios.post(`${backendUrl}/optimize/`, {
        session_id: props.sessionId,
        sel_ticker: props.backTestData.sel_ticker,
        check_flags: props.backTestData.check_flags,
        num_trials: data.numTrials,
      });
      setLoading(false);
      console.log("Optimization Results:", response.data);
      toast.success("Optimization Successful", { duration: 4000 });
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error Status:", error.response.status);
        console.error("Error Data:", error.response.data);
        toast.error(error.response.data.detail, { duration: 4000 });
        setLoading(false);
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
        setLoading(false);
        toast.error("No response received", { duration: 4000 });
      } else {
        // Something else caused the error
        console.error("Error:", error.message);
        setLoading(false);
        toast.error(error.message, { duration: 4000 });
      }
    }
  };

  return (
    <div>
      <Toaster />
      {loading && <Spinner />}
      {props.sessionId && (
        <form onSubmit={handleSubmit(optimization)}>
          <div className="row my-3 align-items-center justify-content-between">
            <div className="mb-3 mb-xl-0 col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-xl-start ">
              <div>
                <label className="small">Enter Number of Trials</label>
                <input
                  type="number"
                  {...register("numTrials", {
                    required: "Number of trials is required", 
                    min: { value: 1, message: "The number of trials must be at least 1" },
                    max: { value: 15, message: "The number of trials cannot exceed 15" },
                  })}
                  className="form-control small inputBoxWidth"
                  placeholder="select value 0-15"
                />
              </div>
              <div className="text-danger small">{errors.numTrials?.message}</div>
            </div>
          </div>
          <div className="my-3">
            <button className="btn border text-white mt-3" type="submit">
              Start Optimization
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default Optimization;