import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import "./Home.css";

const Home = () => {
  // const context = useContext(StepContext);
  // const { setOnboardingStep, setConfigStep } = context;
  // const navigate = useNavigate();
  // const handleOnboardingClick = () => {
  //   setOnboardingStep(2);
  //   navigate("/onboarding");
  // };

//   const handleDashboardClick = () => {
//     sessionStorage.getItem("accessToken")
//       ? navigate("/dashboard1")
//       : navigate("/login");
//   };

//   const [attributes, setAttributes] = useState({});

//   const handleAccountSettingsClick = () => {
//     sessionStorage.getItem("accessToken")
//       ? (() => {
//           setAttributes({
//             role: "button",
//             "data-bs-toggle": "modal",
//           });
//           setConfigStep(2);
//         })()
//       : navigate("/login");
//   };

//   const handleBotCustomizationClick = () => {
//     sessionStorage.getItem("accessToken")
//       ? (() => {
//           setAttributes({
//             role: "button",
//             "data-bs-toggle": "modal",
//           });
//           setConfigStep(1);
//         })()
//       : navigate("/login");
//   };

  return (
    <div
    >
      
      <div 
      style={{ backgroundColor: "black" }}
      >
        <section
          className="container-fluid resPadTop"
          style={{
            width: "100%",
            paddingTop: "80px",
            // fontFamily: "var(--font-nunito)",
          }}
        >
          <div
            className="container col-md-8 pt-4 text-center text-white fw-bolder"
            style={{
              fontSize: "var(--font-size-27xl)",
            }}
          >
            <p className="resHeading">
              
              Unleash the Potential of AI-Driven Trading with Elysées Wealth Management's Bot
            </p>
            <p
              className="resText"
              style={{ fontSize: "var(--font-size-5xl)", fontWeight: "300" }}
            >
              Achieve Efficiency and Accuracy in Every Trade Streamline Your Approach, Maximize Your Profits
            </p>
            <Link
              to="/signup"
              className="scaleUp btn btn-lg border rounded-pill text-white fw-bold px-5 py-3 mt-4"
              tabIndex="-1"
              role="button"
            //   style={{ backgroundColor: "var(--color-cadetblue)" }}
            >
              Start for Free
            </Link>
          </div>

          <div
            className="container-fluid d-flex text-white justify-content-evenly row"
            style={{ marginLeft: "1px", marginTop: "100px" }}
          >
            <div
              className="col-md-5 col-12 rounded-4  p-4 mb-3 mb-md-0 text-center border boxRadius"
            //   style={{ backgroundColor: "var(--color-gray-200)" }}
            >
              <div
                className=""
                // onClick={handleOnboardingClick}
                style={{ cursor: "pointer" }}
              >
                <div className="mb-3">
                  {/* <img
                    style={{ width: "90px", height: "90px" }}
                    alt=""
                    src="../../Assets/api.png"
                  /> */}
                </div>
                <div
                  className="mb-2 fw-bold"
                  style={{ fontSize: "var(--font-size-13xl)" }}
                >
                  API Key Setup Guide
                </div>
              </div>
              <div
                style={{ fontSize: "var(--font-size-lg)", color: "#D4D4D4" }}
              >
                Learn how to set up your API keys for Binance and Bybit to get
                started.
              </div>
            </div>
            <div
              className="col-md-5 col-12 rounded-4  p-4 text-center  border boxRadius"
            //   style={{ backgroundColor: "var(--color-gray-200)" }}
            >
              <div
                className=""
                // onClick={handleDashboardClick}
                style={{ cursor: "pointer" }}
              >
                <div className="mb-3">
                  {/* <img
                    style={{ width: "90px", height: "90px" }}
                    alt=""
                    src="../../Assets/imageremovebgpreview-7-1@2x.png"
                  /> */}
                </div>
                <div
                  className="mb-2 fw-bold"
                  style={{ fontSize: "var(--font-size-13xl)" }}
                >
                  Dashboard
                </div>
              </div>
              <div
                style={{ fontSize: "var(--font-size-lg)", color: "#D4D4D4" }}
              >
                The StableSail Dashboard is the nerve center for your trading
                activities, offering a seamless and intuitive interface that
                brings unparalleled control to your fingertips.
              </div>
            </div>
          </div>

          <div
            className="container-fluid d-flex mt-md-5 mt-3 text-white justify-content-evenly row"
            style={{ marginLeft: "1px" }}
          >
            <div
              className="col-md-5 col-12 rounded-4  p-4 mb-3 mb-md-0 text-center  border boxRadius"
              
            >
              <div
                // {...attributes}
                // data-bs-target="#dashboardModal"
                // onClick={handleAccountSettingsClick}
                style={{ cursor: "pointer" }}
              >
                <div className="mb-3">
                  {/* <img
                    style={{ width: "90px", height: "90px" }}
                    alt=""
                    src="../../Assets/imageremovebgpreview-10-1@2x.png"
                  /> */}
                </div>
                <div
                  className="mb-2 fw-bold"
                  style={{ fontSize: "var(--font-size-13xl)" }}
                >
                  Account Settings
                </div>
              </div>
              <div
                style={{ fontSize: "var(--font-size-lg)", color: "#D4D4D4" }}
              >
                The Account Settings section of the StableSail platform is
                meticulously designed to give users complete control over their
                personal and trading bot configurations.
              </div>
            </div>
            <div
              className="col-md-5 col-12 rounded-4  p-4 text-center border boxRadius"
            //   style={{ backgroundColor: "var(--color-gray-200)" }}
            >
              <div
                // {...attributes}
                // data-bs-target="#dashboardModal"
                // onClick={handleBotCustomizationClick}
                style={{ cursor: "pointer" }}
              >
                <div className="mb-3">
                  {/* <img
                    style={{ width: "90px", height: "90px" }}
                    alt=""
                    src="../../Assets/imageremovebgpreview-11-1@2x.png"
                  /> */}
                </div>
                <div
                  className="mb-2 fw-bold"
                  style={{ fontSize: "var(--font-size-13xl)" }}
                >
                  Bot's Customization
                </div>
              </div>
              <div
                style={{ fontSize: "var(--font-size-lg)", color: "#D4D4D4" }}
              >
                Setting up the bot settings on the StableSail platform is
                designed to be an intuitive and straightforward experience,
                ensuring users can effectively automate their trading strategies
                with minimal intervention.
              </div>
            </div>
          </div>
        </section>
        
      </div>
    </div>
  );
};

export default Home;
