import React from 'react'

function HowItWorks() {
  return (
    <div
    style={{
      background: "black"
    }}
  >
    <div>
      <section
        className="container-fluid"
        style={{
          width: "100%",
          paddingTop: "40px",
        }}
      >
        <div
          className="resHeading container-fluid col-md-11  text-center text-white fw-bolder my-auto"
          style={{
            fontSize: "var(--font-size-39xl)",
          }}
        >
          Trading Bot Guide
        </div>

        <div
          className="container-fluid d-flex my-2  text-white justify-content-evenly row"
          style={{ marginLeft: "1px" }}
        >
          <div
            className="col-md-5 col-12 rounded-4  p-5 mb-3 mb-md-0 text-center border boxRadius"
            // style={{ backgroundColor: "var(--color-gray-200)" }}
          >
            {/* <div className="mb-3">
               <img
                style={{ width: "90px", height: "90px" }}
                alt=""
                src="/group-13@2x.png"
              /> 
            </div> */}
            <div
              className="mb-2"
              style={{ fontSize: "var(--font-size-5xl)" }}
            >
              Functionality
            </div>
            <div>
              Our bot is an advanced trading bot designed to automate your
              trading strategy on popular exchanges such as Binance and Bybit.
              Upon receiving your API key, our bot gains access to your
              exchange account with strict permissions. Importantly, it is
              configured not to have withdrawal capabilities, ensuring your
              funds remain secure. Its primary function is to execute trades
              on your behalf, leveraging sophisticated algorithms and the
              parameters you set.
            </div>
          </div>
          <div
            className="col-md-5 col-12 rounded-4  p-5 text-center border boxRadius"
            // style={{ backgroundColor: "var(--color-gray-200)" }}
          >
            {/* <div className="mb-3">
               <img
                style={{ width: "90px", height: "90px" }}
                alt=""
                src="/group-13-1@2x.png"
              /> 
            </div> */}
            <div
              className="mb-2"
              style={{ fontSize: "var(--font-size-5xl)" }}
            >
              Trading Parameter
            </div>
            <div>
              Our bot offers users full control over their trading
              strategies. You can customize various settings, including trade
              size, risk level, and specific market indicators that the bot
              should consider. This customization ensures that bot's
              trading decisions align with your individual trading goals and
              risk tolerance.
            </div>
          </div>
        </div>

        <div
          className="resHeading container-fluid col-md-11  text-center text-white fw-bolder mt-5"
          style={{
            fontSize: "var(--font-size-39xl)",
          }}
        >
          Real Time Decisions
        </div>

        <div
          className="container-fluid d-flex my-2  text-white justify-content-evenly row"
          style={{ marginLeft: "1px" }}
        >
          <div
            className="col-md-5 col-12 rounded-4 p-5 mb-3 mb-md-0 text-center border boxRadius"
            // style={{ backgroundColor: "var(--color-gray-200)" }}
          >
            {/* <div className="mb-3">
               <img
                style={{ width: "90px", height: "90px" }}
                alt=""
                src="/group-13-2@2x.png"
              /> 
            </div> */}
            <div
              className="mb-2"
              style={{ fontSize: "var(--font-size-5xl)" }}
            >
              Running the Bot
            </div>
            <div>
              The bot makes trading decisions based on a combination of
              pre-defined algorithms and user-configured settings. It
              constantly analyzes market data, seeking opportunities that
              match the criteria you've set. Whether you're focusing on
              short-term fluctuations or long-term trends, StableSail aims to
              capitalize on market movements efficiently.
            </div>
          </div>
          <div
            className="col-md-5 col-12 rounded-4  p-5 text-center border boxRadius"
            // style={{ backgroundColor: "var(--color-gray-200)" }}
          >
            {/* <div className="mb-3">
               <img
                style={{ width: "90px", height: "90px" }}
                alt=""
                src="/group-13-3@2x.png"
              /> 
            </div> */}
            <div
              className="mb-2"
              style={{ fontSize: "var(--font-size-5xl)" }}
            >
              Trade Execution
            </div>
            <div>
              Once a trading opportunity is identified, our bot acts
              swiftly to execute the trade on your exchange account. It
              operates 24/7, ensuring no potentially profitable trades are
              missed. By automating the trading process, bot helps
              users take advantage of market opportunities at any time,
              without the need for constant personal oversight.
            </div>
          </div>
        </div>

        <div
          className="resHeading container-fluid col-md-12 text-center text-white fw-bolder mt-5"
          style={{
            fontSize: "var(--font-size-39xl)",
          }}
        >
          Disclaimer and Risk Acknowledgment
        </div>

        <div
          className="container-fluid d-flex mt-2  text-white justify-content-evenly row"
          style={{ marginLeft: "1px" }}
        >
          <div
            className="col-md-5 col-12 rounded-4 p-5 mb-3 mb-md-0 text-center border boxRadius"
            // style={{ backgroundColor: "var(--color-gray-200)" }}
          >
            {/* <div className="mb-3">
              <img
                style={{ width: "90px", height: "90px" }}
                alt=""
                src="/group-13-4@2x.png"
              />
            </div> */}
            <div
              className="mb-2"
              style={{ fontSize: "var(--font-size-5xl)" }}
            >
              There is no guarantee
            </div>
            <div>
              It's important to understand that trading cryptocurrencies
              involves a high level of risk, including the potential loss of
              your investment. While our  bot is designed to automate your
              trading strategy effectively, the nature of cryptocurrency
              markets means there is always a risk of loss. As with any
              trading activity, there are no guarantees of profit. Users
              should only trade with funds they are prepared to lose and
              consider their experience level and risk tolerance before using
              a trading bot.
            </div>
          </div>
          <div
            className="col-md-5 col-12 rounded-4 p-5 text-center border boxRadius"
            // style={{ backgroundColor: "var(--color-gray-200)" }}
          >
            {/* <div className="mb-3">
              <img
                style={{ width: "90px", height: "90px" }}
                alt=""
                src="/group-13-5@2x.png"
              />
            </div> */}
            <div
              className="mb-2"
              style={{ fontSize: "var(--font-size-5xl)" }}
            >
              Make sure that you know
            </div>
            <div>
              By using our bot, you acknowledge these risks and agree that
              you are responsible for any outcomes of your trading activities.
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  )
}

export default HowItWorks
