import React, { useState } from "react";
import "./Sidebar.css";
import { Link, useNavigate} from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";

function Sidebar(props) {
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate()

  const showSidebar = () => setSidebar(!sidebar);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    toast.success("Logout Succesfull", {
      duration: 4000,
    });
    console.log("logout is clicked");
    navigate("/login");
  }; 

  const handleSwitchChange = (event) => {
    console.log(event.target.checked)
    if (event.target.checked) {
      toast.success((<>
        Bot is&nbsp;<span className="fw-bold"> LIVE</span>
      </>), {
        duration: 4000,
      });
    }
    else {
      toast.success((<>
        Bot is&nbsp;<span className="fw-bold"> OFFLINE</span>
      </>), {
        duration: 4000,
      });
    } 
    }


  return (
    <div>
      <Toaster />
      <div className="sidebar">
        <div className="fixed-top d-flex align-items-center ms-1">
          <div className="menu-bars d-inline">
            <i
              className="bi bi-list"
              style={{ fontSize: "1.5rem" }}
              onClick={showSidebar}
            ></i>
          </div>
          {/* <div className="d-inline ms-2"> Welcome Username</div> */}
        </div>
      </div>
      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <div className="nav-menu-items px-3">
          <div className="sidebar-toggle" onClick={showSidebar}>
            <i class="bi bi-x-lg"></i>
          </div>
          <div className="mb-3"> Welcome <span className="text-danger fw-bold fst-italic">{props.userResponse?.username}</span></div>

          <div class="form-check form-switch">
            <label className="" for="startBot">
              Turn bot on/off
            </label>
            <input
              className="form-check-input startBot"
              type="checkbox"
              role="switch"
              // checked={isChecked}
              id="startBot"
              onChange={handleSwitchChange}
            />
          </div>
          <div>
            <button
              type="button"
              className={`btn px-3 mt-4 mb-2 border rounded-pill text-white`}
              onClick={()=>{handleLogout()}}
            >
             <i class="bi bi-box-arrow-right pe-2"></i>
             Log out
            </button>
          </div>
          <div>
            <button
              type="button"
              className={`btn px-3 border rounded-pill text-white`}
            >
              <i className="bi bi-gear pe-2"></i>
              Settings
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Sidebar;
