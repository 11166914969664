import React, { useState, useEffect } from "react";
import "./LiveTrading.css";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import BootstrapSpinner from "./BootstrapSpinner";

function LiveTrading(props) {
  const [quoteCurrency, setQuoteCurrency] = useState("USDT");
  const [balances, setBalances] = useState(null);
  const [openPosition, setOpenPosition] = useState(null);
  const [loading, setLoading] = useState(false);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const accessToken = localStorage.getItem("accessToken");

  // Debug: Log props at component mount
  useEffect(() => {
    console.group("LiveTrading Props Debug");
    console.log("Received props:", props);

    if (!props.userResponse) {
      console.warn("Warning: userResponse is missing in props.");
    } else {
      if (!props.userResponse.email || !props.userResponse.username) {
        console.warn("Warning: userResponse is missing email or username.");
      }
    }

    console.groupEnd();
  }, [props]);

  const handleCurrencyChange = (event) => {
    setQuoteCurrency(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!accessToken) {
        toast.error("Access token not found. Please log in.");
        return;
      }

      if (!props.userResponse || !props.userResponse.email || !props.userResponse.username) {
        toast.error("User data is missing or invalid.");
        return;
      }

      setLoading(true);

      const payload = {
        balance_request: { quote_currency: quoteCurrency },
        user: {
          email: props.userResponse?.email,
          username: props.userResponse?.username,
        },
      };

      // Log payload and headers
      console.group("API Call Debugging");
      console.log("Backend URL:", backendUrl);
      console.log("Access Token:", accessToken);
      console.log("Payload for /balances:", payload);

      try {
        // Fetch balances
        const balancesResponse = await axios.post(
          `${backendUrl}/balances/`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        console.log("Balances Response:", balancesResponse.data);
        
        // Check if the response has the 'data' key and set the balances
        if (balancesResponse.data && balancesResponse.data.data) {
          setBalances(balancesResponse.data.data);
        } else {
          toast.error("Failed to fetch balance data.");
        }

        // Fetch live mode data
        const liveModePayload = {
          email: props.userResponse?.email,
          username: props.userResponse?.username,
        };

        console.log("Payload for /live_mode:", liveModePayload);

        const liveModeResponse = await axios.post(
          `${backendUrl}/live_mode/`,
          liveModePayload,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        console.log("Live Mode Response:", liveModeResponse.data);
        setOpenPosition(liveModeResponse.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.error("Error while fetching data:");
        if (error.response) {
          // API returned an error response
          console.group("Error Details");
          console.log("Status Code:", error.response.status);
          console.log("Response Data:", error.response.data);
          console.log("Headers:", error.response.headers);
          console.groupEnd();
          toast.error(`Error ${error.response.status}: ${error.response.data.detail || "Failed to fetch data."}`);
        } else if (error.request) {
          // No response received
          console.warn("No response received. Request Details:", error.request);
          toast.error("No response from server. Please try again later.");
        } else {
          // Other errors
          console.error("Unexpected Error:", error.message);
          toast.error(error.message);
        }
      }

      console.groupEnd(); // End API Call Debugging group
    };

    fetchData();
  }, [quoteCurrency, backendUrl, accessToken, props.userResponse]);

  return (
    <div>
      <Toaster />
      <div className="container marginLeft">
        <label className="small">Find Balance</label>
        <select
          value={quoteCurrency}
          onChange={handleCurrencyChange}
          className="form-control small inputBoxWidth"
        >
          <option value="USD">USD</option>
          <option value="USDT">USDT</option>
        </select>
      </div>

      <div className="container marginLeft fs-2 mt-4 mb-3 fw-bold">
        Account Balances ({quoteCurrency})
      </div>

      <div className="container row justify-content-evenly">
        {/* Balance Cards */}
        {loading ? (
          <BootstrapSpinner />
        ) : (
          <>
            <BalanceCard title="Total Balance" value={balances?.total_balance} />
            <BalanceCard title="Free Balance" value={balances?.free_balance} />
            <BalanceCard title="Used Balance" value={balances?.used_balance} />
          </>
        )}
      </div>

      <div className="container marginLeft fs-3 mt-4 mb-3">
        Running Model: Pre-trained Model
      </div>

      <div className="container marginLeft mt-4 mb-3">
        <span className="fs-3">Open Position Summary</span>
        <div style={{ width: "82%" }} className="text-center my-2">
          <table className="table table-bordered text-white">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Position Type</th>
                <th scope="col">Count</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Long</td>
                <td>{openPosition?.open_positions_summary.Count[0]}</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Short</td>
                <td>{openPosition?.open_positions_summary.Count[1]}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

// Reusable Balance Card Component
const BalanceCard = ({ title, value }) => (
  <div
    className="col-md-3 border px-0 my-3 justify-content-center"
    style={{
      width: "230px",
      height: "160px",
      backgroundColor: "rgb(76, 175, 80)",
      borderRadius: "10px",
    }}
  >
    <div className="text-center">
      <div className="my-4 fs-3">{title}</div>
      <div className="fw-bold fs-2">${value?.toFixed(2) || "0.00"}</div>
    </div>
  </div>
);

export default LiveTrading;
