import React from "react";
import "./SignUp.css";
import StartFreeTrial from "./StartFreeTrial";
import Enable2fa from "./Enable2fa";
import { useState, useEffect } from "react";


function SignUp() {
  const [email, setEmail] = useState("");
  useEffect(() => {
    
      console.log("email is:", email);
    
  }, [email]);
  return (
    <>
    <StartFreeTrial setEmail={setEmail}/>
    <Enable2fa email={email}/>
    </>
  );
}

export default SignUp;
